@import '_mixins/fonts';
@import '_mixins/colors';
@import '_mixins/reset';
@import '_mixins/columns';
@import '_mixins/overrides';
@import '_mixins/media';
@import '_components/header';
@import '_components/navigation';
@import '_components/booking';
@import '_components/atv';
@import '_components/intro';
@import '_components/offer';
@import '_components/contact';
@import '_components/friends';
@import '_components/faqs';
@import '_components/drive';
@import '_components/grundriss';
@import '_components/impressum';
@import '_components/footer';

* {
    box-sizing: border-box;
}
html, body {
    display: block;
    position: relative;
}
body {
    font-family: Raleway, sans-serif;
    background-color: @white;
}
main {
    padding-top: 80px;
    @media @u600 {
        padding-top: 50px;
    }
}
section {
    display: block;
    position: relative;
}
img {
    width: 100%;
    height: auto;
}
h2 {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    letter-spacing: 0.8px;
    span {
        display: block;
        font-weight: normal;
    }
}
h3 {
    position: relative;
    display: block;
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    letter-spacing: 0.8px;
    span {
        display: block;
        font-weight: normal;
    }
}
p {
    font-size: 16px;
    line-height: 24px;
}
b {
    display: block;
    font-weight: bold;
}
i {
    font-family: RalewayItalic, sans-serif;
    white-space: nowrap;
}
a {
    color: @orange;
    text-decoration: none;
    transition: all .3s ease;
    &:hover {
        text-decoration: underline;
    }
}
small {
    display: block;
    color: @grey;
    font-weight: normal;
    font-size: 16px;
    line-height: 12px;
}
main ul {
    list-style: none;
    font-size: 16px;
    line-height: 24px;
    li {
        margin-bottom: 20px;
    }
}
.number {
    font-family: Lato, sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: @orange;
}
.number__hight {
    font-family: Lato, sans-serif;
    font-weight: bold;
    font-size: 19px;
    line-height: 36px; 
    color: @orange;
}
#drive {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u600 { 
        padding: 0px 5px;
        padding-bottom: 120px;   
    }
    #drive__text {
        padding-left: 50px;
        @media @u1400 {
            padding-left: 35px;
        }
        @media @u1040 {
            width: 100%;
            margin: 0px;
            margin-bottom: 75px;
            padding: 0px 35px;
        }
    }
    #drive__map {
        padding-left: 50px;
        @media @u1250 {
            padding-left: 35px;
        }
        @media @u1040 {
            width: 100%;
            margin: 0px;
            padding: 0px 35px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    p {
        margin-top: 30px;
    }
}
#friends {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u1250 {
        padding-bottom: 0px;
    }
    @media @u600 { 
        padding: 0px 5px;
    }
    &>div {
        padding-left: 50px;
        @media @u1400 {
            padding-left: 35px;
        }
        h3 {
            padding-right: 0px;
        }
    }
    .col {
        @media @u1250 {
            width: 100%;
            max-width: 600px;
            margin: 0px;
            display: block;
            margin-bottom: 75px;
        }
        @media @u1040 {
            padding: 0px 35px;
        }
    }
    img {
        margin: 30px 0px 15px 0px;
        height: 40px;
        width: auto;
        &#czernys_logo {
            height: 80px;
            margin-left: -20px;
        }
    }
}
@font-face {
  font-family: "Raleway";
  font-display: block;
  font-weight: 300;
  src: url("https://cdn.mmsrv.de/fonts/Raleway/Regular.ttf") format('truetype');
}
@font-face {
  font-family: "Raleway";
  font-display: block;
  font-weight: 700;
  src: url("https://cdn.mmsrv.de/fonts/Raleway/Bold.ttf") format('truetype');
}
@font-face {
  font-family: "RalewayItalic";
  font-display: block;
  font-weight: 300;
  src: url("https://cdn.mmsrv.de/fonts/Raleway/Italic.ttf") format('truetype');
}
@font-face {
  font-family: "Lato";
  font-display: block;
  font-weight: 700;
  src: url("https://cdn.mmsrv.de/fonts/Lato/Bold.ttf") format('truetype');
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.row .col {
  width: calc(100% / 3 - 80px / 3);
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1040px) {
  .row .col {
    width: 100%;
  }
}
.row .col.col__left {
  margin-right: calc(40px / 3 * 2);
}
@media (max-width: 1040px) {
  .row .col.col__left {
    margin-right: 0px;
  }
}
.row .col.col__mid {
  margin-right: calc(40px / 3);
  margin-left: calc(40px / 3);
}
@media (max-width: 1040px) {
  .row .col.col__mid {
    margin-right: 0px;
    margin-left: 0px;
  }
}
.row .col.col__right {
  margin-left: calc(40px / 3 * 2);
}
@media (max-width: 1040px) {
  .row .col.col__right {
    margin-left: 0px;
  }
}
.row .col.col__midright {
  margin-left: calc(40px / 3);
  width: calc(100% / 3 * 2 - 80px / 6);
}
@media (max-width: 1040px) {
  .row .col.col__midright {
    margin-left: 0px;
    width: 100%;
  }
}
.row .col.col__all {
  width: 100%;
}
@media (max-width: 1040px) {
  .row {
    max-width: 670px;
    margin: 0px auto;
  }
}
button {
  appearance: none;
  border: 0px solid white;
  font-size: 20px;
  line-height: 24px;
  font-family: Raleway, sans-serif;
}
button:focus {
  outline: none;
}
hr {
  display: block;
  margin: 0px;
  border: 0px solid white;
  height: 1px;
  background: #F2994A;
}
table,
tbody,
thead {
  width: 100%;
  margin: 0px;
  text-align: left;
}
table {
  margin-bottom: 40px;
}
th {
  font-weight: bold;
}
td,
th {
  padding: 6px 0px;
  line-height: 22px;
}
td:nth-child(2) {
  text-align: right;
}
input {
  padding: 20px 15px;
  border: 1px solid #C4C4C4;
  display: block;
  width: 100%;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin-top: 5px;
}
input:focus {
  border-color: #F2994A;
  outline: none;
}
label {
  margin-top: 20px;
  display: block;
  font-weight: normal;
  color: #C4C4C4;
}
header {
  display: block;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  text-align: right;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0);
  transition: box-shadow 0.7s ease;
}
@media (max-width: 600px) {
  header {
    height: 50px;
  }
}
header.act {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}
#logo {
  position: fixed;
  top: 0;
  left: 70px;
  height: 80px;
}
@media (max-width: 1400px) {
  #logo {
    left: 85px;
  }
}
@media (max-width: 1040px) {
  #logo {
    left: 55px;
  }
}
@media (max-width: 600px) {
  #logo {
    left: 35px;
    height: 50px;
  }
}
#logo img {
  height: 50px;
  margin-top: 15px;
  width: auto;
}
@media (max-width: 600px) {
  #logo img {
    margin-top: 5px;
    height: 40px;
  }
}
#menu {
  display: inline-block;
  vertical-align: top;
}
#menu #burger {
  display: block;
  height: 80px;
  width: 95px;
  padding: 32px 35px;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.3s ease;
}
@media (max-width: 600px) {
  #menu #burger {
    height: 50px;
    padding: 10px 35px;
  }
}
#menu #burger span {
  display: block;
  position: relative;
  background-color: #F2994A;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}
#menu #burger span:nth-child(3) {
  margin-bottom: 0px;
}
#menu #burger.act span:nth-child(1) {
  transform: rotate(45deg) translate(4px, 5px);
}
#menu #burger.act span:nth-child(2) {
  transform: rotate(-45deg) translate(1px, -1px);
}
#menu #burger:hover span {
  background-color: #f4a662;
}
#menu #burger:active {
  background-color: #f2f2f2;
}
#menu #burger:active span {
  background-color: #ef7e1a;
}
#cta {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 600px) {
  #cta {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  }
}
#cta__button {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  height: 80px;
  padding: 30px 60px;
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  color: #ffffff;
}
#cta__button:hover {
  background: linear-gradient(90deg, #f4d064 0%, #f4a662 100%) 0% 0% no-repeat;
}
#cta__button:active {
  background: linear-gradient(90deg, #efbb1c 0%, #ef7e1a 100%) 0% 0% no-repeat;
}
@media (max-width: 600px) {
  #cta__button {
    width: 100%;
    height: auto;
    padding: 20px;
  }
}
#navigation {
  position: fixed;
  display: flex;
  top: 80px;
  left: 0px;
  width: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  text-align: left;
  backdrop-filter: blur(2px);
  padding: 70px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}
@media (max-width: 600px) {
  #navigation {
    top: 49px;
  }
}
@media (max-width: 1250px) {
  #navigation {
    padding: 35px;
  }
}
#navigation.act {
  opacity: 1;
  visibility: visible;
}
#navigation.scroll {
  bottom: 0px;
  overflow: scroll;
}
#navigation #navigation__headline p {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: 0.8px;
  position: relative;
  display: block;
}
#navigation #navigation__headline p span {
  display: block;
  font-weight: normal;
}
#navigation #navigation__content {
  position: relative;
  display: block;
  width: 100%;
}
#navigation ul {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1250px) {
  #navigation ul {
    display: block;
  }
}
#navigation li {
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}
#navigation a {
  display: block;
  position: relative;
  padding: 12px 0px;
}
#navigation #navigation__socialmedia {
  margin-top: 30px;
  display: flex;
}
#navigation #navigation__socialmedia a {
  margin-right: 20px;
}
#navigation #navigation__socialmedia img {
  height: 20px;
  width: 30px;
}
#booking {
  position: fixed;
  display: flex;
  top: 80px;
  left: 0px;
  width: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  text-align: left;
  backdrop-filter: blur(2px);
  padding: 70px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}
@media (max-width: 600px) {
  #booking {
    top: 50px;
  }
}
#booking.act {
  opacity: 1;
  visibility: visible;
}
#booking #booking__headline p {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: 0.8px;
  position: relative;
  display: block;
  margin-bottom: 30px;
}
#booking #booking__headline p span {
  display: block;
  font-weight: normal;
}
#booking #booking__content {
  position: relative;
  display: block;
  width: 100%;
}
#booking #booking__menu {
  display: flex;
}
#booking a {
  display: block;
  position: relative;
  padding: 50px 50px;
  width: 100%;
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  color: #ffffff;
  margin-left: 20px;
  font-size: 20px;
  line-height: 24px;
}
#booking a:hover {
  opacity: 0.7;
  text-decoration: none;
}
#booking a:nth-child(1) {
  margin-left: 0px;
}
#atv {
  display: block;
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 1250px) {
  #atv {
    padding-bottom: 75px;
  }
}
@media (max-width: 830px) {
  #atv {
    padding-bottom: 0px;
  }
}
#atv #atv__img {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100vh - 290px);
  min-height: 33vw;
  object-fit: cover;
}
@media (max-width: 830px) {
  #atv #atv__img {
    height: calc(100vh - 480px);
  }
}
#atv #atv__title {
  padding: 0px 70px;
  display: flex;
  position: relative;
}
@media (max-width: 1400px) {
  #atv #atv__title {
    padding: 0px 35px;
  }
}
@media (max-width: 1040px) {
  #atv #atv__title {
    padding: 0px;
  }
}
@media (max-width: 830px) {
  #atv #atv__title {
    display: block;
  }
}
#atv #atv__title #title__headline {
  width: calc(66.66% - 40px);
  height: 230px;
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  padding: 85px 50px;
  margin-top: -40px;
  display: inline-block;
  margin-right: 40px;
}
@media (max-width: 1040px) {
  #atv #atv__title #title__headline {
    width: 100%;
    flex: 1 1 100%;
    margin-right: 0px;
  }
}
@media (max-width: 830px) {
  #atv #atv__title #title__headline {
    padding: 85px 35px;
    margin-top: 0px;
    height: auto;
  }
}
#atv #atv__title #title__headline h1 {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 0.8px;
}
#atv #atv__title #title__headline h1 span {
  font-weight: normal;
  display: block;
}
@media (max-width: 830px) {
  #atv #atv__title #title__headline h1 {
    max-width: 530px;
    margin: 0px auto;
    display: block;
  }
}
#atv #atv__title #title__stats {
  width: 33.33%;
  height: 190px;
  padding: 0px 50px;
  display: flex;
  align-items: center;
}
@media (max-width: 1250px) {
  #atv #atv__title #title__stats {
    min-width: 370px;
  }
}
@media (max-width: 1040px) {
  #atv #atv__title #title__stats {
    width: 280px;
    min-width: 280px;
    display: block;
    padding: 50px 50px 0px 50px;
  }
}
@media (max-width: 830px) {
  #atv #atv__title #title__stats {
    display: flex;
    max-width: 600px;
    width: 100%;
    margin: 0px auto;
    padding: 0px 35px;
  }
}
#atv #atv__title #title__stats div {
  width: 50%;
  position: relative;
}
@media (max-width: 1040px) {
  #atv #atv__title #title__stats div {
    width: 100%;
  }
}
@media (max-width: 830px) {
  #atv #atv__title #title__stats div {
    width: 50%;
  }
}
@media (max-width: 1040px) {
  #atv #atv__title #title__stats div#fairgrounds {
    margin-top: 15px;
  }
}
@media (max-width: 830px) {
  #atv #atv__title #title__stats div#fairgrounds {
    margin-top: 0px;
  }
}
#intro {
  display: block;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  #intro {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 1250px) {
  #intro {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
  }
}
@media (max-width: 600px) {
  #intro {
    padding: 0px 5px;
  }
}
#intro > div {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  #intro > div {
    padding-left: 35px;
  }
}
@media (max-width: 1250px) {
  #intro #intro__text {
    order: 3;
    width: 100%;
    max-width: 600px;
    margin-bottom: 75px;
  }
}
@media (max-width: 1040px) {
  #intro #intro__text {
    margin: 0px auto;
    margin-bottom: 75px;
    padding: 0px 35px;
  }
}
@media (max-width: 600px) {
  #intro #intro__text {
    padding: 0px 20px;
  }
}
@media (max-width: 1250px) {
  #intro #intro__facts {
    order: 2;
    margin: 0px;
    width: 100%;
    margin-bottom: 75px;
  }
}
@media (max-width: 1040px) {
  #intro #intro__facts {
    padding: 0px 35px;
  }
}
@media (max-width: 600px) {
  #intro #intro__facts {
    padding: 0px 20px;
  }
}
#intro #intro__stats {
  border: 1px solid #F2994A;
  padding: 50px 50px 20px 50px;
}
@media (max-width: 1400px) {
  #intro #intro__stats {
    padding: 35px 35px 15px 35px;
  }
}
@media (max-width: 1250px) {
  #intro #intro__stats {
    order: 1;
    margin: 0px;
    width: 100%;
    margin-bottom: 75px;
  }
}
@media (max-width: 600px) {
  #intro #intro__stats {
    padding: 15px 15px 15px 15px;
  }
}
#intro #intro__stats img {
  width: 80px;
  margin: 0px auto;
}
#intro p {
  margin-top: 30px;
}
#intro #facts {
  margin-top: 30px;
  max-width: 400px;
}
@media (max-width: 1250px) {
  #intro #facts {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  #intro #facts li {
    width: calc(100% / 3);
    padding-right: 30px;
  }
}
@media (max-width: 1040px) {
  #intro #facts li {
    width: 100%;
    max-width: 400px;
    padding: 0px;
  }
}
#intro hr {
  margin-bottom: 30px;
}
#intro .seats,
#intro .equipment {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-bottom: 30px;
}
@media (max-width: 1250px) {
  #intro .seats,
  #intro .equipment {
    width: calc(100% / 3);
  }
  #intro .seats.seats,
  #intro .equipment.seats {
    width: calc(100% / 4);
  }
}
@media (max-width: 1040px) {
  #intro .seats.seats,
  #intro .equipment.seats {
    width: calc(100% / 2);
  }
}
@media (max-width: 830px) {
  #intro .seats,
  #intro .equipment {
    width: 100%;
  }
  #intro .seats.seats,
  #intro .equipment.seats {
    width: calc(100% / 2);
  }
}
#intro .equipment img {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  margin-right: 10px;
}
#intro .equipment p {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  width: auto;
  max-width: 145px;
  min-width: 100px;
}
#grundsitz {
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  display: block;
  cursor: pointer;
  height: 80px;
  padding: 20px 20px;
  color: #fff;
  width: 100%;
  margin-bottom: 30px;
}
#offer,
#offer__overlay {
  position: relative;
  display: block;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  #offer,
  #offer__overlay {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 1250px) {
  #offer,
  #offer__overlay {
    padding-bottom: 0px;
  }
}
@media (max-width: 600px) {
  #offer,
  #offer__overlay {
    padding: 0px 5px;
  }
}
#offer > div,
#offer__overlay > div {
  border: 1px solid #F2994A;
}
#offer > div h3,
#offer__overlay > div h3 {
  min-height: 84px;
  padding-right: 80px;
}
@media (max-width: 1250px) {
  #offer .col,
  #offer__overlay .col {
    width: 100%;
    margin: 0px;
    margin-bottom: 75px;
  }
}
#offer .offer__header,
#offer__overlay .offer__header {
  padding: 40px 50px 0px 50px;
  margin-bottom: 20px;
  background: #ffffff;
}
@media (max-width: 1400px) {
  #offer .offer__header,
  #offer__overlay .offer__header {
    padding: 25px 35px 0px 35px;
  }
}
#offer .offer__content,
#offer__overlay .offer__content {
  padding: 0px 50px;
}
@media (max-width: 1400px) {
  #offer .offer__content,
  #offer__overlay .offer__content {
    padding: 0px 35px;
  }
}
#offer .price,
#offer__overlay .price {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  position: absolute;
  right: 0px;
  bottom: 14px;
  text-align: right;
}
#offer .price span,
#offer__overlay .price span {
  font-weight: lighter;
  font-family: Raleway, sans-serif;
}
@media (max-width: 600px) {
  #offer .price,
  #offer__overlay .price {
    position: relative;
    padding-top: 10px;
    text-align: left;
  }
  #offer .price span,
  #offer__overlay .price span {
    display: inline-block;
  }
}
#offer #offer__daily,
#offer__overlay #offer__daily {
  padding: 0px;
  margin-top: -20px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}
#offer #offer__daily__wrap,
#offer__overlay #offer__daily__wrap {
  padding: 20px 50px 0px 50px;
}
@media (max-width: 1400px) {
  #offer #offer__daily__wrap,
  #offer__overlay #offer__daily__wrap {
    padding: 20px 35px 0px 35px;
  }
}
#offer .offer__description,
#offer__overlay .offer__description {
  min-height: 150px;
  padding-bottom: 15px;
}
@media (max-width: 1250px) {
  #offer .offer__description,
  #offer__overlay .offer__description {
    min-height: 0px;
    max-width: 600px;
  }
}
#offer .offer__roomsize,
#offer__overlay .offer__roomsize {
  margin-bottom: 40px;
}
@media (max-width: 1250px) {
  #offer .offer__roomsize,
  #offer__overlay .offer__roomsize {
    margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1250px) {
  #offer .offer__fairgrounds,
  #offer__overlay .offer__fairgrounds {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1250px) {
  #offer table,
  #offer__overlay table {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-right: 40px;
  }
  #offer table thead,
  #offer__overlay table thead,
  #offer table tbody,
  #offer__overlay table tbody {
    width: 100%;
    display: table;
  }
}
@media (max-width: 1040px) {
  #offer table,
  #offer__overlay table {
    display: table;
    width: 100%;
    padding-right: 0px;
  }
}
@media (max-width: 600px) {
  #offer .texted td,
  #offer__overlay .texted td {
    display: block;
    text-align: left;
  }
  #offer .texted td:nth-child(1),
  #offer__overlay .texted td:nth-child(1) {
    font-weight: bold;
  }
}
@media (max-width: 600px) {
  #offer tr,
  #offer__overlay tr {
    margin-bottom: 5px;
  }
}
@media (max-width: 1250px) {
  #offer hr,
  #offer__overlay hr {
    margin-bottom: 20px;
  }
}
#offer #offer__size > p,
#offer__overlay #offer__size > p {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1250px) {
  #offer #offer__size > p,
  #offer__overlay #offer__size > p {
    width: auto;
  }
}
#offer .offer__daily__title,
#offer__overlay .offer__daily__title {
  margin: -1px -1px 0px -1px;
  padding: 50px 50px 12px 50px;
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  color: #ffffff;
}
@media (max-width: 1400px) {
  #offer .offer__daily__title,
  #offer__overlay .offer__daily__title {
    padding: 40px 35px 12px 35px;
  }
}
#offer__overlay {
  position: fixed;
  top: -100%;
  width: 100%;
  left: 0px;
  display: block;
  padding: 0px 70px;
  border-top: 0px solid #ffffff;
}
@media (max-width: 1400px) {
  #offer__overlay {
    padding: 0px 35px;
  }
}
@media (max-width: 1250px) {
  #offer__overlay {
    display: none;
  }
}
#offer__overlay.act {
  top: 80px;
}
#offer__overlay > div {
  border: 0px solid transparent;
}
#offer__overlay .col {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
#offer__overlay .offer__daily__title {
  margin: -1px -1px 0px -1px;
  padding: 30px 50px 12px 50px;
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  color: #ffffff;
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media (max-width: 1400px) {
  #offer__overlay .offer__daily__title {
    padding: 20px 35px 12px 35px;
  }
}
#offer__overlay .offer__daily__title.act {
  opacity: 1;
}
#offer__overlay .offer__header {
  opacity: 0;
  transition: opacity 0.3s ease;
}
#offer__overlay .offer__header.act {
  opacity: 1;
}
#contact {
  display: block;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  #contact {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 600px) {
  #contact {
    padding: 0px 5px;
    padding-bottom: 120px;
  }
}
#contact #contact__form {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  #contact #contact__form {
    padding-left: 35px;
  }
}
@media (max-width: 1040px) {
  #contact #contact__form {
    width: 100%;
    margin-bottom: 75px;
    padding: 0px 35px;
  }
}
#contact #contact__img {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  #contact #contact__img {
    padding-left: 35px;
  }
}
@media (max-width: 1040px) {
  #contact #contact__img {
    width: 100%;
    margin: 0px;
    padding: 0px 35px;
  }
}
#contact #contact__img img {
  width: 100%;
  height: auto;
}
#contact p {
  margin-top: 30px;
}
#contact button {
  width: 50%;
  border: 1px solid #C4C4C4;
  height: 70px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  background-color: #ffffff;
  transition: all 0.3s ease;
}
#contact button#contact__telefon {
  border-right-width: 0px;
}
#contact button:active {
  background-color: #f2f2f2;
}
#contact #bvzkont.hide {
  display: none;
}
#contact #opt__phone,
#contact #opt__mail,
#contact #send,
#contact #response {
  display: none;
}
#contact #opt__phone.act,
#contact #opt__mail.act,
#contact #send.act,
#contact #response.act {
  display: block;
}
#contact #contactfrom.hide {
  display: none;
}
#contact #send {
  background: linear-gradient(90deg, #F2C94C 0%, #F2994A 100%) 0% 0% no-repeat;
  width: 100%;
  border: 0px solid transparent;
  color: #ffffff;
}
#friends {
  display: block;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  #friends {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 1250px) {
  #friends {
    padding-bottom: 0px;
  }
}
@media (max-width: 600px) {
  #friends {
    padding: 0px 5px;
  }
}
#friends > div {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  #friends > div {
    padding-left: 35px;
  }
}
#friends > div h3 {
  padding-right: 0px;
}
@media (max-width: 1250px) {
  #friends .col {
    width: 100%;
    max-width: 600px;
    margin: 0px;
    display: block;
    margin-bottom: 75px;
  }
}
@media (max-width: 1040px) {
  #friends .col {
    padding: 0px 35px;
  }
}
#friends img {
  margin: 30px 0px 15px 0px;
  height: 40px;
  width: auto;
}
#friends img#czernys_logo {
  height: 80px;
  margin-left: -20px;
}
#faqs {
  display: block;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  #faqs {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 600px) {
  #faqs {
    padding: 0px 5px;
    padding-bottom: 120px;
  }
}
#faqs > div {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  #faqs > div {
    padding-left: 35px;
  }
}
#faqs > div h3 {
  padding-right: 0px;
}
@media (max-width: 1250px) {
  #faqs .col {
    width: 100%;
    max-width: 600px;
    display: block;
    margin-bottom: 75px;
  }
}
#faqs img {
  margin: 30px 0px 15px 0px;
  height: 40px;
  width: auto;
}
#faqs img#czernys_logo {
  height: 80px;
  margin-left: -20px;
}
#faqs .faq__title {
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 5px;
  display: block;
  cursor: pointer;
}
#faqs .faq__content {
  max-height: 0px;
  height: auto;
  overflow: hidden;
  border-bottom: 1px solid #F2994A;
  transition: all 0.3s ease;
}
#faqs .faq__content p {
  padding: 10px 0px;
}
#faqs .faq.act .faq__content {
  max-height: 500px;
}
#drive {
  display: block;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  #drive {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 600px) {
  #drive {
    padding: 0px 5px;
    padding-bottom: 120px;
  }
}
#drive #drive__text {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  #drive #drive__text {
    padding-left: 35px;
  }
}
@media (max-width: 1040px) {
  #drive #drive__text {
    width: 100%;
    margin: 0px;
    margin-bottom: 75px;
    padding: 0px 35px;
  }
}
#drive #drive__map {
  padding-left: 50px;
}
@media (max-width: 1250px) {
  #drive #drive__map {
    padding-left: 35px;
  }
}
@media (max-width: 1040px) {
  #drive #drive__map {
    width: 100%;
    margin: 0px;
    padding: 0px 35px;
  }
}
#drive #drive__map img {
  width: 100%;
  height: auto;
}
#drive p {
  margin-top: 30px;
}
#grundrisse_wrap {
  width: 100%;
  text-align: left;
  padding: 0px 70px 0px 70px;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  transition: all 0.7s ease;
}
@media (max-width: 1400px) {
  #grundrisse_wrap {
    padding: 0px 70px 0px 70px;
  }
}
@media (max-width: 1040px) {
  #grundrisse_wrap {
    padding: 0px 35px;
    max-width: 600px;
    margin: 0px auto;
  }
}
#grundrisse_wrap.act {
  max-height: 10000px;
  padding-bottom: 140px;
}
@media (max-width: 1040px) {
  #grundrisse_wrap.act {
    padding-bottom: 75px;
  }
}
#grundrisse_wrap #grundrisse {
  display: flex;
  justify-content: space-between;
  margin: 0px -10px;
}
@media (max-width: 1040px) {
  #grundrisse_wrap #grundrisse {
    flex-wrap: wrap;
    margin: 0px 0px;
  }
}
#grundrisse_wrap .grundriss {
  margin: 0px 10px;
}
#grundrisse_wrap .grundriss img {
  width: auto;
  max-width: 100%;
}
#grundrisse_wrap .grundriss p {
  margin-left: 10px;
}
@media (max-width: 1040px) {
  #grundrisse_wrap .grundriss {
    width: 50%;
    margin: 0px 0px;
  }
}
#grundrisse_wrap #grundriss__headline p {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: 0.8px;
  position: relative;
  display: block;
  margin-bottom: 30px;
  padding-left: 35px;
}
#grundrisse_wrap #grundriss__headline p span {
  display: block;
  font-weight: normal;
}
#impressum {
  display: block;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
#impressum h1 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  letter-spacing: 0.8px;
}
@media (max-width: 1400px) {
  #impressum {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 1250px) {
  #impressum {
    padding-bottom: 0px;
  }
}
@media (max-width: 600px) {
  #impressum {
    padding: 0px 5px;
  }
}
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0px 70px;
  padding-bottom: 120px;
}
@media (max-width: 1400px) {
  footer {
    padding: 0px 35px;
    padding-bottom: 120px;
  }
}
@media (max-width: 1250px) {
  footer {
    padding-bottom: 60px;
  }
}
@media (max-width: 600px) {
  footer {
    padding: 0px 5px 120px 5px;
    flex-direction: column;
  }
  footer > div {
    margin-bottom: 60px;
  }
  footer > div a {
    margin: 0 20px;
  }
}
footer > .legals {
  padding-left: 50px;
}
@media (max-width: 1400px) {
  footer > .legals {
    padding-left: 35px;
  }
}
@media (max-width: 600px) {
  footer > .legals {
    padding-left: 0;
  }
}
footer > .legals h3 {
  padding-right: 0px;
}
@media (max-width: 1250px) {
  footer .col {
    width: 100%;
    max-width: 600px;
    margin: 0px;
    display: block;
    margin-bottom: 75px;
  }
}
@media (max-width: 1040px) {
  footer .col {
    padding: 0px 35px;
  }
}
footer a {
  margin-right: 20px;
}
footer .mm__wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
@media (max-width: 600px) {
  footer .mm__wrap {
    align-items: center;
  }
}
footer .mm__wrap p {
  font-size: 0.75em;
}
footer .mm__wrap .mm__signature {
  height: 1em;
  width: auto;
}
* {
  box-sizing: border-box;
}
html,
body {
  display: block;
  position: relative;
}
body {
  font-family: Raleway, sans-serif;
  background-color: #ffffff;
}
main {
  padding-top: 80px;
}
@media (max-width: 600px) {
  main {
    padding-top: 50px;
  }
}
section {
  display: block;
  position: relative;
}
img {
  width: 100%;
  height: auto;
}
h2 {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
h2 span {
  display: block;
  font-weight: normal;
}
h3 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  letter-spacing: 0.8px;
}
h3 span {
  display: block;
  font-weight: normal;
}
p {
  font-size: 16px;
  line-height: 24px;
}
b {
  display: block;
  font-weight: bold;
}
i {
  font-family: RalewayItalic, sans-serif;
  white-space: nowrap;
}
a {
  color: #F2994A;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  text-decoration: underline;
}
small {
  display: block;
  color: #C4C4C4;
  font-weight: normal;
  font-size: 16px;
  line-height: 12px;
}
main ul {
  list-style: none;
  font-size: 16px;
  line-height: 24px;
}
main ul li {
  margin-bottom: 20px;
}
.number {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #F2994A;
}
.number__hight {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 19px;
  line-height: 36px;
  color: #F2994A;
}
